import { get, post } from "App/Network/Axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, ScrollableDynamicTable, TOAST } from "retro/index";
import { DownloadCsv } from "retro/Utils";

export default function DAMIS({ transId,DAInvoiceData }) {

  const [downloading, setDownloading] = useState(false);
  const downloadData = () => {
    setDownloading(true);
    get(
      `/meta/da/${transId}`,
      (e, r) => {
        setDownloading(false);
        if (r) {
          let data = [];
          let headers = [
            'Id',
            "Remarks",
            "Admin Email",
            'Date',
            'Transaction Id',
            'Type',
            'DA Name',
            'DA ID',
            'Reference Type',
            'Amount',
            'Balance',
            'Start Balance',
            'End Balance'
          ];
          data.push(headers);
          r?.items.forEach((item) => {
            data.push([
              item?.id || "-",
              item?.remarks || "-",
              item?.attrs?.["admin.email"] || "-",
              item?.date?moment(item.date).format("DD MMM YYYY HH-mm a"):"-",
              item.transaction?.transId || "-",
              item.transaction?.category || "-",
              item.daName||"-",
              item?.daId || "-",
              item?.type || "-" ,
              item?.amount || "-",
              item?.balance || "-",
              `INR ${Math.abs(item?.startBalance || 0)} ${
                item?.startBalance >= 0 ? "CR" : "DB"
              }`,
              `INR ${Math.abs(item?.endBalance || 0)} ${
                item?.endBalance >= 0 ? "CR" : "DB"
              }`,
            ]);
          });

          DownloadCsv(
            `DA_Mis_${transId || ""}.csv`,
            data
          );
        } else {
          TOAST.handleError(e);
        }
      }
    );
  };
  
  return (
    <div>
      <div className="flex horizontally justify-between center">
        <p style={{ fontSize: 16, fontWeight: "600" }}>DA MIS</p>
        <div className="download_btn" >
          <Button
            className="btn btn-primary"
            loading={downloading}
            onClick={downloadData}
          >
            Download Data
          </Button>
        </div>
      </div>
      <ScrollableDynamicTable
        tableStyle={{
          borderSpacing: 0,
        }}
        data={{
          items: DAInvoiceData?.map((item) => {
            return {
              content: item,
              render: [
                {
                  children: (
                    <div>
                      <p className="text">#{item.id}</p>
                      <p className="text-small text-light">{item.remarks}</p>
                      <p className="text-small text-light">
                        {item?.attrs?.["admin.email"] || "-"}
                      </p>
                    </div>
                  ),
                  weight: 2,
                },
                {
                  weight: 1,
                  title: moment(item.date).format("DD MMM YYYY HH-mm a"),
                },
                {
                  weight: 1,
                  title: item.transaction?.transId || "-",
                },
                {
                  weight: 2,
                  title: item.transaction?.category || "-",
                },
                {
                  weight: 1,
                  title: item.daName || "-",
                },
                {
                  weight: 2,
                  title: item.daId,
                },
                {
                  weight: 1,
                  title: item.type,
                },
                {
                  weight: 1,
                  title: `INR ${item.amount}`,
                },
                {
                  weight: 1,
                  title: `INR ${item.balance}`,
                },
                {
                  weight: 1,
                  title: `INR ${Math.abs(item?.startBalance || 0)} ${
                    item?.startBalance >= 0 ? "CR" : "DB"
                  }`,
                },
                {
                  weight: 1,
                  title: `INR ${Math.abs(item?.endBalance || 0)} ${
                    item?.endBalance >= 0 ? "CR" : "DB"
                  }`,
                },
              ],
            };
          }),
        }}
        headers={[
          {
            weight: 2,
            title: "Details",
          },
          {
            weight: 1,
            title: "Date",
          },
          {
            weight: 1,
            title: "Transaction Id",
          },
          {
            weight: 2,
            title: "Type",
          },
          {
            weight: 1,
            title: "DA Name",
          },
          {
            weight: 2,
            title: "DA ID",
          },
          {
            weight: 1,
            title: "Reference Type",
          },
          {
            weight: 1,
            title: "Amount",
          },
          {
            weight: 1,
            title: "Balance",
          },
          {
            weight: 1,
            title: "Start Balance",
          },
          {
            weight: 1,
            title: "End Balance",
          },
        ]}
      />
    </div>
  );
}
