import React, { useEffect, useRef, useState } from "react";
import {
  Attachment,
  Button,
  Input,
  KeyValue,
  NavDivider,
  Navigation,
  ScrollableDynamicTable,
  Seperator,
  SidePane,
  TOAST,
} from "retro/index";
import { get, post } from "App/Network/Axios";
import { useHistory } from "react-router-dom";
import FetchDetails from "./FetchDetails";
import moment from "moment";
import OpenIcon from "@atlaskit/icon/glyph/open";
import AttachmentContainer from "./AttachmentContainer";
import Credit from "./Credit";
import Debit from "./Debit";

export default function index() {
  const [formData, setFormData] = useState({
    RID: undefined,
    details: undefined,
  });
  const GSTIN = useRef();
  const GST = useRef();
  const EMAIL = useRef();
  const BASEFARE = useRef();
  const DOCTYPE = useRef();
  const HISTORY = useHistory();
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(undefined);
  const [creditModal, setcreditModal] = useState(false);
  const [debitModal, setdebitModal] = useState(false);

  function click(data) {
    setModal(data);
  }

  function onClose() {
    setModal(undefined);
  }


  function fetchRIDetails() {
    let rid = formData?.RID;
    get(`/meta/mis/${rid}`, (e, r) => {
      if (r) {
        setFormData({
          RID: rid,
          details: r,
        });
      }
    });
  }


  return (
    <div>
      {modal && (
        <SidePane
          title="Invoice Details"
          description=""
          preview={
            modal?.document ? (
              <AttachmentContainer files={[{ path: modal?.document }]} />
            ) : (
              <div className="pd6">No Attachments found</div>
            )
          }
          onClose={onClose}
        >
          <div className="text-small mt4">
            <h3 className="mb2">Request Details</h3>

            <KeyValue
              title="Document Number"
              value={modal.documentNumber || "-"}
            />

            <KeyValue title="Document Type" value={modal.documentType || "-"} />
            <KeyValue title="Status" value={modal?.status || "-"} />
            <KeyValue title="Merchant Name" value={modal.merchantName || "-"} />
            <KeyValue
              title="Seller GST"
              value={modal?.sellerGst?.name || "-"}
            />
            <KeyValue title="Buyer GST" value={modal?.buyerGst?.name || "-"} />
            <KeyValue
              title="Document Date"
              value={
                moment(modal.documentDate).format("DD MMM YYYY HH:mm a") || "-"
              }
            />

            <Seperator />
            <p className="fw-bold">Attachments:</p>

            <div className="mt2 flex horizontally">
              <Attachment url={modal?.document} />
            </div>
          </div>
        </SidePane>
      )}
      {debitModal && (
        <Debit
          debitModal={debitModal}
          setdebitModal={setdebitModal}
          formData={formData}
          fetchRIDetails={fetchRIDetails}
        />
      )}
      {creditModal && (
        <Credit
          creditModal={creditModal}
          setcreditModal={setcreditModal}
          formData={formData}
          fetchRIDetails={fetchRIDetails}

        />
      )}

      <Navigation
        backUrl={"back"}
        title="Manual Invoice"
        children={
          <>
            {formData?.details && (
              <div style={{ display: "flex", flexDirection: "row" }}>
                <p style={{ fontSize: 16, fontWeight: "600", marginTop: 20 }}>
                  Trans ID : {formData.details?.items?.[0]?.transId}
                </p>
                <p
                  style={{
                    fontSize: 16,
                    fontWeight: "600",
                    marginTop: 20,
                    marginLeft: 20,
                  }}
                >
                  Booking Amount : {formData.details?.bookingAmount}
                </p>
                <p
                  style={{
                    fontSize: 16,
                    fontWeight: "600",
                    marginTop: 20,
                    marginLeft: 20,
                  }}
                >
                  Consumed Amount : {formData.details?.total}
                </p>
                <p
                  style={{
                    fontSize: 16,
                    fontWeight: "600",
                    marginTop: 20,
                    marginLeft: 20,
                  }}
                >
                  Remaining Amount :{formData.details?.bookingAmount-formData.details?.total} 
                </p>
                <div style={{ flex:1, display:"flex", justifyContent:"end", marginLeft:20}}>
                <Button
                  title="Credit"
                  onClick={() => {
                    setcreditModal(true);
                  }}
                >
                  <p>Credit</p>
                </Button>
                <Button
                  style={{ marginLeft: 10 }}
                  title="Credit"
                  onClick={() => {
                    setdebitModal(true);
                  }}
                >
                  <p>Debit</p>
                </Button>
                </div>

               
              </div>
            )}
          </>
        }
      />
      <NavDivider />
      {!formData.details ? (
        <FetchDetails data={formData} setData={setFormData} category="" />
      ) : (
        <>
          <ScrollableDynamicTable
            setPage={(page) => {}}
            width={175}
            tableStyle={{
              borderSpacing: 0,
            }}
            data={{
              items: formData?.details?.items.map((item) => {
                return {
                  content: item,
                  render: [
                    {
                      children: (
                        <div>
                          <p className="text">{item.documentNumber}</p>
                        </div>
                      ),
                      weight: 1,
                    },
                    {
                      children: (
                        <div>
                          <p className="text">{item.documentType}</p>
                        </div>
                      ),
                      weight: 1,
                    },
                    {
                      weight: 1,
                      title: item.status||"-",
                    },
                    {
                      weight: 1,
                      title: item.merchantName||"-",
                    },
                    {
                      weight: 1,
                      title: item.buyerGst.name||"-",
                    },
                    {
                      weight: 1,
                      title: item.buyerGst.gstin||"-",
                    },
                    {
                      weight: 1,
                      title: item.sellerGst.name||"-",
                    },
                    {
                      weight: 1,
                      title: item.sellerGst.gstin||"-",
                    },
                    {
                      weight: 1,
                      title: moment(item.documentDate).format(
                        "DD MMM YYYY HH:mm a"
                      ),
                    },

                    {
                      weight: 0.5,
                      buttons: [
                        {
                          title: "View",
                          className: "btn-secondary btn-sm",
                          // before: <OpenIcon size="small" />,
                          onClick: () => click(item),
                        },
                      ],
                    },
                  ],
                };
              }),
            }}
            headers={[
              {
                weight: 1,
                title: "Document Number",
              },
              {
                weight: 1,
                title: "Document Type",
              },
              {
                weight: 1,
                title: "Status",
              },
              {
                weight: 1,
                title: "Merchant Name",
              },
              {
                weight: 1,
                title: "Buyer GST",
              },
              {
                weight: 1,
                title: "Buyer GST No.",
              },
              {
                weight: 1,
                title: "Seller GST",
              },
              {
                weight: 1,
                title: "Seller GST No.",
              },
              {
                weight: 1,
                title: "Document Date",
              },
              {
                weight: 0.5,
                title: "Action",
              },
            ]}
          />
        </>
      )}
    </div>
  );
}


