import React, {useEffect, useState} from 'react'
import {DateRange, Loader, Navigation, ScrollableDynamicTable, Button, TOAST} from "../../../../../../retro";
import Moment from 'moment'
import {get} from "../../../../../Network/Axios";
import {useHistory} from "react-router-dom";
import RBAC from 'App/HOCs/RBAC';
import ROLES from 'App/Constants/Roles';
import { DownloadCsv } from 'retro/Utils';

export default function index() {
	const HISTORY = useHistory();
	const [Dates, setDates] = useState();
	const [loading] = useState(false);
	const [buttonLoader,setButtonLoader] = useState(false);
	const [Data, SetData] = useState({
		total: 0,
		currentPage: 0,
		totalPages: 0,
		items: []
	})

	useEffect(()=>{
		if(Dates){
			get(`/meta/hotel`,(e,r)=>{
				if(r){
					SetData({
						...r,
						currentPage: Dates.page ? Dates.page : 0,
						totalPages: r.totalPages > 0 ? r.totalPages : Data.totalPages,
						total: r.total > 0 ? r.total : Data.total
					})
				} else {
					Alert.handleError(e)
				}
			},{
				start:Dates.startDate,
				end:Dates.endDate,
				offset:Dates.page
			})
		}
	},[Dates])

	const downloadData = () => {
		setButtonLoader(true);
		get(
		  '/meta/hotel',
		  (e, r) => {
			if (e) {
			  setButtonLoader(false);
			  TOAST.error('Unable to download');
			  console.log(e)
			  return;
			}
			let data = [];
			let headers = [
			 	"Trans Id",
				// "Trip Id",
				"RI Num",
				"Created Date & Time",
				"Fulfillment Date & Time",
				"Traveller Name",
				"Traveller Email Id",
				"Booker Name",
				"Booker Email Id",
				"Client Code",
				"Client Name",
				"Provder",
				"Payment Type",
				"Amount",
			];
			data.push(headers);
			console.log("r",r)
			r?.items?.map((item) => {
				data?.push([
					item.transId,
					// Trip Id
					// item?.tripId || "-",
					// RI Num
					item?.referenceId || "-",
					// Created Date & Time
					item?.createdAt?Moment(item.createdAt).format("DD MM YYYY HH:mm A"):"-",
					// Fullfillment Date & Time
					item?.createdAt?Moment(item.updatedAt).format("DD MM YYYY HH:mm A"):"-",
					// Traveller Name
					travellers(item),
					// Travellers Email Id
					travellersEmail(item),
					// Booker Name
					item?.invoiceConfig?.bookedBy || "-",
					// Booker Email ID
					item?.invoiceConfig?.bookedByEmail || "-",
					// "Client Code"
					item.client.code  || "-" ,
					// "Client Name"
					item.client.name  || "-" ,
					// "Provider"
					item.provider  || "-",
					// "Payment Type"
					item.paymentType || "-",
					// "Amount"
					item?.amount || "-"
				]);
			  }
			);
			DownloadCsv(
			  `hotel_bookings_${Moment(Dates.startDate).format('DD_MM_YYYY')}_${Moment(
				Dates.endDate
			  ).format('DD_MM_YYYY')}.csv`,
			  data
			);
			setButtonLoader(false);
		  },
		  {
			start: Dates.startDate,
			end: Dates.endDate,
		  }
		);
	  };

	let travellers = (item) => {
		let string = undefined;
		try {
			item.ticket.travellers.forEach((traveller) => {
				if (!string) {
					string = `${traveller.name}`;
				} else {
					string = `${string} , ${traveller.name}`;
				}
			});
		}catch (e){
			return "-";
		}
		return string;
	};

	const travellersEmail = (item) => {
		let string = undefined;
		try {
			item.ticket.travellers.forEach((traveller) => {
				if (!string) {
					string = `${traveller.email}`;
				} else {
					string = `${string} , ${traveller.email}`;
				}
			});
		}catch (e){
			return "-";
		}
		return string
	};

	if(loading){
		return (
			<div>
				<div style={{
					margin:'0 -4rem',
					padding:'2rem 4rem',
					borderBottom:'1px solid #dedede'
				}}>
					<div className="flex horizontally center-vertically">
						<div className="flex-1">
							<DateRange  defaultValue={{
								startDate:Moment().add(-31,'d').valueOf(),
								endDate : Moment().valueOf()
							}}  onChange={()=>{}} className='' label='Export from and to' type='range'/>
						</div>
					</div>
				</div>
				<div className="pd6">
					<Loader/>
				</div>
			</div>
		)
	}

	let items = [];
	Data.items.forEach(item=>{
		items.push({
			content: item,
			render: [
				item.transId,
				// Trip Id
				// item?.tripId || "-",
				// RI Num
				item?.referenceId || "-",
				// Created Date & Time
				item?.createdAt?Moment(item.createdAt).format("DD MM YYYY HH:mm A"):"-",
				// Fullfillment Date & Time
				item?.createdAt?Moment(item.updatedAt).format("DD MM YYYY HH:mm A"):"-",
				// Traveller Name
				{
					content: (
						<p style={{
							overflow: "hidden",
							display: "-webkit-box",
							WebkitBoxOrient: "vertical",
							WebkitLineClamp: 3
						}}>{travellers(item)}</p>
					)
				},
				// Travellers Email Id
				{
					content: (
						<p style={{
							overflow: "hidden",
							display: "-webkit-box",
							WebkitBoxOrient: "vertical",
							WebkitLineClamp: 3
						}}>{travellersEmail(item)}</p>
					)
				},
				// Booker Name
				item?.invoiceConfig?.bookedBy || "-",
				// Booker Email ID
				item?.invoiceConfig?.bookedByEmail || "-",,
				item.client.code,item.client.name,item.provider,item.paymentType
				,item.amount,{content:(<p className="fw-bold pointer" onClick={()=>{
						HISTORY.push(`/app/apps/meta/transaction/${item.transId}`)
					}}>View</p>)}

			].map(title=>{
				if(title.content){
					return {weight:2,children:title.content}
				}else{
					return {weight:2,title}
				}
			})
		})
	})
	return (
		<div style={{width:'100%'}}>
            <Navigation backUrl={'back'} title="Hotel Bookings" chips={['Meta',"Hotel","Bookings"]} description="Hotel bookings appears below."/>
			<div style={{
				margin:'0 -4rem',
				padding:'2rem 4rem',
				borderBottom:'1px solid #dedede'
			}}>
				<div className="flex horizontally center-vertically">
					<div className="flex-1">
						<DateRange  defaultValue={{
							startDate:Moment().add(-31,'d').valueOf(),
							endDate : Moment().valueOf()
						}}  onChange={dates=>{
							setDates({
								...dates,
								page:0
							})
						}} className='' label='Export from and to' type='range'/>
					</div>
				</div>
			</div>
			<RBAC role={ROLES.REPORTS}>
				<Button
					loading={buttonLoader}
					onClick={downloadData}
					className="btn btn-black"
				>
					Download Data
				</Button>
			</RBAC>
			<div >

				<ScrollableDynamicTable
					autoTrigger={false}
					tableStyle={{
						borderSpacing: 0
					}}
					width={150}
					search={Dates}
					setPage={page => {
						setDates({
							...Dates,
							page,
							total:Dates.totalPages
						})
					}}
					data={{
						current: Data.currentPage,
						total: Data.totalPages,
						items
					}} headers={[
					"Trans Id",
					// "Trip Id",
					"RI Num",
					"Created Date & Time",
					"Fulfillment Date & Time",
					"Traveller Name",
					"Traveller Email Id",
					"Booker Name",
					"Booker Email Id",
					"Client Code","Client Name","Provder","Payment Type",
					"Amount","Actions"
				].map((item)=>({
					weight: 2,
					title: item,
				}))}/>
			</div>
        </div>
	)
}
