import React, {useState} from 'react'
import Pdf from './pdf.png'
import NoFile from './no_file.png'
import { Empty } from 'retro/index'

export default function AttachmentContainer({files}){
	const [current,setCurrent] = useState(
		files.length>0?files[0].path:undefined
	)

	if(!files.length){
		return <div className='flex center h-100 w-100'><Empty label='No Attachments'/></div>
	}

	return (
		<div className="pd4">
			<p className='mb4 text-secondary-color'>
				Attachment Preview
			</p>
			<div style={{
				height:'60vh',
				borderRadius:12,
				border:'1px solid var(--theme-variant-color)',
				width:'100%'
			}}>
				{
					current===undefined &&
					<img alt="no file" src={NoFile} style={{
						height:'40%',
						width:'40%',
						margin:'30%',
						objectFit:'contain'
					}}/>
				}{
				current!==undefined && current.endsWith(".pdf") &&
				<iframe sandbox title='file' src={current} style={{
					height:'100%',
					width:'100%',
					borderRadius:12,
				}}/>

			}
				{
					current!==undefined &&
					(current.includes("map.eka.io") ||current.endsWith(".jpeg") || current.endsWith(".png") || current.endsWith(".jpg") || current.endsWith(".webp"))
					&&
					<img alt="no file" src={current} style={{
						height:'80%',
						width:'80%',
						margin:'10%',
						objectFit:'contain'
					}}/>
				}
			</div>
			{
				files.length>0&&<>
					<p className='mb2 mt4 text-secondary-color'>
						All Attachments:
					</p>
					<div className="flex-wrap flex">
						{
							files.filter(item=>item.path).map(({path,title})=>{
								if(path.endsWith(".pdf")){
									return (
									<div className='mr1 mb1 rounded-md pd1'>	
										<img onClick={()=>{setCurrent(path)}} alt={title} src={Pdf} style={{
											borderRadius:12,
											marginRight:12,
											cursor:'pointer',
											marginBottom:12,
											width:60,
											height:60,
											padding:2,
											border:current===path?'2px solid var(--theme-primary-color)':'none'
										}}/>
									<p className="fw-500 text-small ml1">
										{title}
									</p>
									</div>	
									)
								}else if(path.endsWith('.csv')){

								}else {
									return (
										<div className='mr1 mb1 rounded-md pd1'>
										<img onClick={()=>{setCurrent(path)}} key={path} alt={title} src={path} style={{
											borderRadius:12,
											marginRight:12,
											marginBottom:12,
											width:60,
											cursor:'pointer',
											height:60,
											padding:2,
											border:current===path?'2px solid var(--theme-primary-color)':'none'
										}}/>
										<p className="fw-500 text-small ml1">
											{title}
								    	</p>		
										</div>
									)
								}
							})
						}
					</div>
				</>
			}
		</div>
	)
}
