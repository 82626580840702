/**
 * Author[Lakshay Jain]
 * Version 1.0.1
 * 15th December | 1.0.1 | Shubham Rawat | added filters and documents download option
 */
import React, { useEffect, useRef, useState } from "react";
import { Button, DateInput, DateRange, Loader, Navigation, SelectInput, TOAST } from "retro";
import Moment from "moment";
import { get } from "App/Network/Axios";
import { DownloadCsv } from "retro/Utils";
import Graph from "./graph-stats-circle.svg";
import RBAC from "App/HOCs/RBAC";
import ROLES from "App/Constants/Roles";
import { placeOfSupply } from "../../Flight/AgencyBosTravellerFile/Utils";
import MultiSelectDropdown from "App/Components/MultiSelectDropdown";
import { downloadAll } from "../../Flight/Repository/utils";
import useConfigHook from "App/Hooks/useConfigHook";


export default function index() {
  const [loading, setLoading] = useState(false);
  const DATETYPE = useRef();
  const [dates,setDates] = useState({
    start: Moment().add(-31,'d').valueOf(),
    end: Moment().valueOf(),
  });
  const STARTDATE = useRef();
  const ENDDATE = useRef();
  const CONFIG=useConfigHook();

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedDoc, setSelectedDoc] = useState([]);
  const [clientList, setClientList] = useState([]);
  const PERSONS = {
    "aditya.vyawhare@antrepriz.com":true,
    "aavishkar@antrepriz.com":true,
  
  }
  const docTypes = [
    "Invoice",
    "Service Invoice",
    "Ancillary Charges",
    "Credit Note",
    "Cancellation Charges"
  ]

  // setting the client list on load
  useEffect(()=>{
    let templist = [];
    get('/clients/dump',(e,r)=>{
      if(r){
        r.items.map((client)=>{
          templist.push(client.code)
        })
        setClientList([...templist]);
      }
      },{
      active:true
    })
  },[])

  // function to parse json
  const parseJSON = (str)=>{
    let obj = undefined;
    try {
      obj = JSON.parse(str);
    } catch (e) {
      obj = undefined;
    }
    return obj;
  }

  // download docs function
  const downloadDocs = () => {
    if(selectedDoc.length === 0){
      TOAST.error('Select documnet types');
      return;
    }
    setLoading(true);
    get(
      `/meta/hotel/reseller`,
      (e, r) => {
        let documents = [];
        if (e) {
          console.log("e", e);
          setLoading(false);
          TOAST.error("Unable to download");
          return;
        }
        r.bookings.map((item) => {
          try {
            // filter data based on selected clients
            if(selectedOptions.length>0 && !selectedOptions.includes(item.client?.code)){
              return; // return if client code does not match
            }
            // filter the documents on the basis of doctype
            if(
              item.invoiceConfig.invoice &&
              item.invoiceConfig.invoice!=="SKIPPED" &&
              item.invoiceConfig.invoice!=="PENDING" &&
              item.invoiceConfig.invoice!=="FAILED" &&
              selectedDoc.includes('Invoice')
            ){
              documents.push({
                title: `${item.transId}_${item.client.code}_Invoice.pdf`,
                file: item.invoiceConfig.invoice
              });
            }
            if(
              item.invoiceConfig.ancillaryInvoice &&
              item.invoiceConfig.ancillaryInvoice!=="SKIPPED" &&
              item.invoiceConfig.ancillaryInvoice!=="PENDING" &&
              item.invoiceConfig.ancillaryInvoice!=="FAILED" &&
              selectedDoc.includes('Ancillary Charges')
            ){
              documents.push({
                title: `${item.transId}_${item.client.code}_Ancillary_Charges.pdf`,
                file: item.invoiceConfig.ancillaryInvoice
              });
            }
            if(
              item.invoiceConfig.serviceInvoice &&
              item.invoiceConfig.serviceInvoice!=="SKIPPED" &&
              item.invoiceConfig.serviceInvoice!=="PENDING" &&
              item.invoiceConfig.serviceInvoice!=="FAILED" &&
              selectedDoc.includes('Service Invoice')
            ){
              documents.push({
                title: `${item.transId}_${item.client.code}_Service_Invoice.pdf`,
                file: item.invoiceConfig.serviceInvoice
              });
            }
            if(
              item.invoiceConfig.creditNote &&
              item.invoiceConfig.creditNote!=="SKIPPED" &&
              item.invoiceConfig.creditNote!=="PENDING" &&
              item.invoiceConfig.creditNote!=="FAILED" &&
              selectedDoc.includes('Credit Note')
            ){
              documents.push({
                title: `${item.transId}_${item.client.code}_Credit_Note.pdf`,
                file: item.invoiceConfig.creditNote
              });
            }
            if(
              item.invoiceConfig.cancellationInvoice &&
              item.invoiceConfig.cancellationInvoice!=="SKIPPED" &&
              item.invoiceConfig.cancellationInvoice!=="PENDING" &&
              item.invoiceConfig.cancellationInvoice!=="FAILED" &&
              selectedDoc.includes('Cancellation Charges')
            ){
              documents.push({
                title: `${item.transId}_${item.client.code}_Cancellation_Charges.pdf`,
                file: item.invoiceConfig.cancellationInvoice
              });
            }
            }
            catch(e){}
          })
          // download and zip if the docs are found
          if(documents.length>0){
            downloadAll(`document_collection`,documents,()=>{
              setLoading(false);
            })
          }else{
            TOAST.error("Found No Documents");
          }
      },{
        start: Moment(STARTDATE.current.value).valueOf(),
        end: Moment(ENDDATE.current.value).add(1,'d').valueOf(),
        filterOn: DATETYPE.current.value,
      }
    )
  }

  // download report function
  const downloadData = () => {
    if(selectedDoc.length === 0){
      TOAST.error('Select documnet types');
      return;
    }
    setLoading(true);
    get(
      `/meta/hotel/reseller`,
      (e, r) => {
        if (e) {
          console.log("e", e);
          setLoading(false);
          TOAST.error("Unable to download");
          return;
        }
        let data = [];
        // report headers
        let headers = [
          "Id",
          "Trans Id",
          "Reference Id",
          "Provider",
          "Provider Id",
          "Merchant Type",
          "Cart Type",
          "Booking Status",
          "Booker Name",
          "Booker Email Address",
          "Client Code",
          "Cust Code",
          "Gstin",
          "Pan",
          "Tan",
          "Client Name",
          "Created On Date",
          "Created On Time",
          "Updated On Date",
          "Updated On Time",
          "CT Gstin",
          "CT State",
          "Buyer Entity",
          "Buyer Gstin",
          "State Of Supply",
          "Payment Type",
          "Pg Order Id",
          "Pg Payment Id",
          "Pg Refund Id",
          "Imported By",
          "Amount Collected",
          "Line Item Amount",
          "Ledger Id",
          "Ledger Amount",
          "Ledger Balance",
          "Hsn",
          "Document Type",
          "Document Number",
          "Document Irn",
          "Document",
          "Booking Voucher",
          "Document Date", /* Invoice Date */
          "Hotel Name",
          "Hotel Location",
          "Hotel State", /* State where the hotel is located */
          "Hotel Check-in",
          "Hotel Check-out",
          "Traveller Name",
          "Traveller Email",
          "Traveller Gender",
          "Traveller Type",
          "PNR",
          "Base Fare",
          "Slab",
          "Other Charges",
          "SGST",
          "CGST",
          "IGST",
          "TOTAL",
          "Remarks", /* Generation Message */
          "Invoice Error",
          "Total Rooms",
          "Total Nights",
          "Total Travellers", 
          "Purchase Price Per Night Exc. of GST",
          "Total Purchase Price Inc. of GST",
          "Purchase Price GST",
          "Sell Price Per Night Exc. of GST",
          "Total Sell Price Inc. of GST",
          "Sell Price GST",
          "Rate Type",
          "ClearTrip Assured"
          
        ];
        if(PERSONS[CONFIG.email]){
					headers.push("Platform Markup")
				  }
        data.push(headers);
        // let resellerHotel = [];
        // resellerHotel.push(r.bookings);

        r.bookings.map((item) => {
			const { invoiceRequest,serviceRequest } = item.invoiceConfig;
      let ctAssured= "-"

      if(item?.ctAssured?.toLowerCase()==="true" || item?.ctAssured?.toLowerCase()==="yes"){
        ctAssured="YES"
        }else if(item?.ctAssured?.toLowerCase()==="false" || item?.ctAssured?.toLowerCase()==="no"){

        }else{
          ctAssured="-"
        }



			/* Parse Invoice date if present */
			const parsedInvoiceRequest = invoiceRequest ? parseJSON(invoiceRequest) : undefined;
			const invoiceDate = parsedInvoiceRequest ? Moment(parsedInvoiceRequest.data.docDtls.dt, 'DD/MM/YYYY').format('DD MMM YYYY') : '-';

			/* Parse Service date if present */
			const parsedServiceRequest = serviceRequest ? parseJSON(serviceRequest) : undefined;
			const serviceDate = parsedServiceRequest ? Moment(parsedServiceRequest.data.docDtls.dt, 'DD/MM/YYYY').format('DD MMM YYYY') : '-';

          try {
            // filter data based on selected clients
            if(selectedOptions.length>0 && !selectedOptions.includes(item.client?.code)){
              return; // return if client code does not match
            }
            if (
              item.status !== "FAILED" &&
              item.status !== "ONGOING" &&
              (item.invoiceConfig?.reseller ||
                (item.invoiceConfig != null &&
                  item.invoiceConfig.serviceInvoice !== "SKIPPED"))
            ) {
              if (item.invoiceConfig?.reseller && selectedDoc.includes('Invoice'))
                data.push([
                  item.id,
                  item.transId,
                  item.referenceId||"-",
                  item.provider,
                  item.providerId || item.ticket?.flowId ||  "-",
                  item.ticket?.importMerchantType || "-",
                  "Booking",
                  item.status,
                  // Booker Name
                  item.ticket.travellers[0].name || "-",
                  // Booker Email Address
                  item.ticket.travellers[0].email || "-",
                  item.client.code,
                  item.client.attrs?.CUST_CODE || "-",
                  item.client.attrs?.gstin || "-",
                  item.client.attrs?.pan || "-",
                  item.client.attrs?.tan || "-",
                  item.client.name,
                  Moment(item.createdAt).format("DD MMM YYYY"),
                  Moment(item.createdAt).format("HH:mm a"),
                  Moment(item.updatedAt).format("DD MMM YYYY"),
                  Moment(item.updatedAt).format("HH:mm a"),
                  item.invoiceConfig?.sellerGst?.gstin||"-",
                  item.invoiceConfig?.placeOfSupply||"-",
                  item.invoiceConfig?.userGst?.name || "-",
                  item.invoiceConfig?.userGst?.gstin || "-",
                  placeOfSupply(item.invoiceConfig.userGst?.gstin || "-"),
                  item.paymentType,
                  item.attrs?.orderId || "-",
                  item.attrs?.paymentId || "-",
                  item.attrs?.refundId || "-",
                  item.attrs?.raisedBy || "-",
                  item.amount,
                  item.invoiceConfig?.irnFare?.total,
                  item.ledger?.ledgerId || "-",
                  item.ledger?.amount || "-",
                  item.ledger?.balance || "-",
                  "996311",
                  "Invoice",
                  item.invoiceConfig?.invoiceNo || "-",
                  item.invoiceConfig?.invoiceIrn || "-",
                  item.invoiceConfig?.invoice || "-",
                  item?.invoiceConfig?.file || "-",
				          invoiceDate,
                  item.ticket?.name || "-",
                  item.ticket?.city || "-",
                  item.invoiceConfig?.placeOfSupply || "-", /* State where the hotel is located */
                  item.ticket?.date?.in || "-",
                  item.ticket?.date?.out || "-",
                  item.ticket?.travellers[0]?.name || "-",
                  item.ticket?.travellers[0]?.email || "-",
                  item.ticket?.travellers[0]?.gender || "-",
                  item.ticket?.travellers[0]?.type || "-",
                  item.ticket?.pnr || "-",
                  item.invoiceConfig?.irnFare?.base || "-",
                  item.invoiceConfig?.irnFare?.slab || "-",
                  item.invoiceConfig?.irnFare?.oth || "-",
                  item.invoiceConfig?.irnFare?.sGst || "-",
                  item.invoiceConfig?.irnFare?.cGst || "-",
                  item.invoiceConfig?.irnFare?.iGst || "-",
                  item.invoiceConfig?.irnFare?.total || "-",
                  item?.invoiceConfig?.invoiceResponse?.message || "-", /* Remarks Column */
                  item?.invoiceConfig?.invoiceError || "-", /* Invoice Error message */
                  item?.ticket?.criteria?.rooms?.length || "0",
                  item.ticket?.night || "-",
                  item.ticket?.travellers.length || "-",
                  item.ticket?.purchasePricePerNight || "-",
                  item.ticket?.totalPurchasePrice || "-",
                  item.ticket?.purchasePriceGstSlab || "-",
                  item.ticket?.pricePerNight || "-",
                  item.ticket?.totalSellPrice || "-",
                  item.ticket?.gstSlab || "-",
                  item?.rateType||"-",
                  ctAssured||"-",
                  PERSONS[CONFIG.email]?
                  item.invoiceConfig?.platformMarkup || "-":"",
                ]);
              if (
                item.invoiceConfig != null &&
                item.invoiceConfig.serviceInvoice != null &&
                item.invoiceConfig.serviceInvoice !== "SKIPPED" &&
                selectedDoc.includes('Service Invoice')
              ) {
                data.push([
                  item.id,
                  item.transId,
                  item.referenceId||"-",
                  item.provider,
                  item.providerId || item.ticket?.flowId ||  "-",
                  item.ticket?.importMerchantType || "-",
                  "Service Invoice",
                  item.status,
                  item.client.code,
                  item.client.attrs?.CUST_CODE || "-",
                  item.client.attrs?.gstin || "-",
                  item.client.attrs?.pan || "-",
                  item.client.attrs?.tan || "-",
                  item.client.name,
                  Moment(item.createdAt).format("DD MMM YYYY"),
                  Moment(item.createdAt).format("HH:mm a"),
                  Moment(item.updatedAt).format("DD MMM YYYY"),
                  Moment(item.updatedAt).format("HH:mm a"),
                  "27AAHCC1775A1ZX",
                  "Maharashtra",
                  item.invoiceConfig?.userGst?.name || "-",
                  item.invoiceConfig?.userGst?.gstin || "-",
                  placeOfSupply(item.invoiceConfig.userGst?.gstin || "-"),
                  item.paymentType || "-",
                  item.attrs?.orderId || "-",
                  item.attrs?.paymentId || "-",
                  item.attrs?.refundId || "-",
                  item.attrs?.raisedBy || "-",
                  item.amount || "-",
                  item.invoiceConfig?.serviceFare.total,
                  item.ledger?.ledgerId || "-",
                  item.ledger?.amount || "-",
                  item.ledger?.balance || "-",
                  "998551",
                  "Service Invoice",
                  item.invoiceConfig?.serviceInvoiceNo || "-",
                  item.invoiceConfig?.serviceResponse?.response?.data?.Irn ||
                    "-",
                  item.invoiceConfig?.serviceInvoice || "-",
                  item?.invoiceConfig?.file || "-",
				  serviceDate,
                  item.ticket?.name || "-",
                  item.ticket?.city || "-",
				  item.invoiceConfig?.placeOfSupply || "-", /* State where the hotel is located */
                  item.ticket?.date?.in || "-",
                  item.ticket?.date?.out || "-",
                  item.ticket?.travellers[0]?.name || "-",
                  item.ticket?.travellers[0]?.email || "-",
                  item.ticket?.travellers[0]?.gender || "-",
                  item.ticket?.travellers[0]?.type || "-",
                  item.ticket?.pnr || "-",
                  item.invoiceConfig?.serviceFare.base || "-",
                  item.invoiceConfig?.serviceFare.slab || "-",
                  item.invoiceConfig?.serviceFare.oth || "-",
                  item.invoiceConfig?.serviceFare.sGst || "-",
                  item.invoiceConfig?.serviceFare.cGst || "-",
                  item.invoiceConfig?.serviceFare.iGst || "-",
                  item.invoiceConfig?.serviceFare.total || "-",
                  item?.invoiceConfig?.invoiceResponse?.message || "-", /* Remarks Column */
                  item?.invoiceConfig?.invoiceError || "-", /* Invoice Error message */
                  item?.ticket?.criteria?.rooms?.length || "0",
                  item.ticket?.night || "-",
                  item.ticket?.travellers.length || "-",
                  item.ticket?.purchasePricePerNight || "-",
                  item.ticket?.totalPurchasePrice || "-",
                  item.ticket?.purchasePriceGstSlab || "-",
                  item.ticket?.pricePerNight || "-",
                  item.ticket?.totalSellPrice || "-",
                  item.ticket?.gstSlab || "-",
                  item?.rateType||"-",
                  ctAssured||"-",
                  PERSONS[CONFIG.email]?item.invoiceConfig?.platformMarkup || "-":"",
                ]);
              }
              if (
                item.invoiceConfig !== null &&
                item.invoiceConfig.ancillaryInvoice !== null &&
                item.invoiceConfig.ancillaryInvoice !== "SKIPPED" &&
                selectedDoc.includes('Ancillary Charges')
              ) {
                let lineItem = JSON.parse(item.invoiceConfig.ancillaryRequest);
                data.push([
                  item.id,
                  item.transId,
                  item.referenceId||"-",
                  item.provider,
                  item.providerId || item.ticket?.flowId ||  "-",
                  item.ticket?.importMerchantType || "-",
                  "Ancillary Charges",
                  item.status,
                  item.client.code,
                  item.client.attrs?.CUST_CODE || "-",
                  item.client.attrs?.gstin || "-",
                  item.client.attrs?.pan || "-",
                  item.client.attrs?.tan || "-",
                  item.client.name,
                  Moment(item.createdAt).format("DD MMM YYYY"),
                  Moment(item.createdAt).format("HH:mm a"),
                  Moment(item.updatedAt).format("DD MMM YYYY"),
                  Moment(item.updatedAt).format("HH:mm a"),
                  "27AAHCC1775A1ZX",
                  "Maharashtra",
                  item.invoiceConfig?.userGst?.name || "-",
                  item.invoiceConfig?.userGst?.gstin || "-",
                  placeOfSupply(item.invoiceConfig.userGst?.gstin || "-"),
                  item.paymentType,
                  item.attrs?.orderId || "-",
                  item.attrs?.paymentId || "-",
                  item.attrs?.refundId || "-",
                  item.attrs?.raisedBy || "-",
                  item.amount,
                  lineItem?.data?.valDtls?.totInvVal || "-",
                  item.ledger?.ledgerId || "-",
                  item.ledger?.amount || "-",
                  item.ledger?.balance || "-",
                  "996311",
                  "Ancillary Charges",
                  lineItem?.data?.docDtls?.no || "-",
                  item.invoiceConfig?.ancillaryIrn || "-",
                  item.invoiceConfig?.ancillaryInvoice || "-",
                  item?.invoiceConfig?.file || "-",
				  "-",
                  item.ticket?.name || "-",
                  item.ticket?.city || "-",
				  item.invoiceConfig?.placeOfSupply || "-", /* State where the hotel is located */
                  item.ticket?.date?.in || "-",
                  item.ticket?.date?.out || "-",
                  item.ticket?.travellers[0]?.name || "-",
                  item.ticket?.travellers[0]?.email || "-",
                  item.ticket?.travellers[0]?.gender || "-",
                  item.ticket?.travellers[0]?.type || "-",
                  item.ticket?.pnr || "-",
                  lineItem?.data?.valDtls?.assVal || "-",
                  item.invoiceConfig?.ancillaryFare?.slab || "-",
                  `0`,
                  lineItem?.data?.valDtls?.sgstVal || "-",
                  lineItem?.data?.valDtls?.cgstVal || "-",
                  lineItem?.data?.valDtls?.igstVal || "-",
                  lineItem?.data?.valDtls?.totInvVal || "-",
                  item?.invoiceConfig?.invoiceResponse?.message || "-", /* Remarks Column */
                  item?.invoiceConfig?.invoiceError || "-", /* Invoice Error message */
                  item?.ticket?.criteria?.rooms?.length || "0",
                  item.ticket?.night || "-",
                  item.ticket?.travellers.length || "-",
                  item.ticket?.purchasePricePerNight || "-",
                  item.ticket?.totalPurchasePrice || "-",
                  item.ticket?.purchasePriceGstSlab || "-",
                  item.ticket?.pricePerNight || "-",
                  item.ticket?.totalSellPrice || "-",
                  item.ticket?.gstSlab || "-",
                  item?.rateType||"-",
                  ctAssured||"-",
                  PERSONS[CONFIG.email]?
          item.invoiceConfig?.platformMarkup || "-":"",
                ]);
              }
              if (
                item.invoiceConfig !== null &&
                item.invoiceConfig.creditNote !== null &&
                item.invoiceConfig.creditNote !== "PENDING" &&
                selectedDoc.includes('Credit Note')
              ) {
                let lineItem = JSON.parse(item.invoiceConfig.creditRequest);
                data.push([
                  item.id,
                  item.transId,
                  item.referenceId||"-",
                  item.provider,
                  item.providerId || item.ticket?.flowId ||  "-",
                  item.ticket?.importMerchantType || "-",
                  "Credit Note",
                  item.status,
                  item.client.code,
                  item.client.attrs?.CUST_CODE || "-",
                  item.client.attrs?.gstin || "-",
                  item.client.attrs?.pan || "-",
                  item.client.attrs?.tan || "-",
                  item.client.name,
                  Moment(item.createdAt).format("DD MMM YYYY"),
                  Moment(item.createdAt).format("HH:mm a"),
                  Moment(item.updatedAt).format("DD MMM YYYY"),
                  Moment(item.updatedAt).format("HH:mm a"),
                  item.invoiceConfig?.sellerGst?.gstin||"-",
                  "Maharashtra",
                  item.invoiceConfig?.userGst?.name || "-",
                  item.invoiceConfig?.userGst?.gstin || "-",
                  placeOfSupply(item.invoiceConfig.userGst?.gstin || "-"),
                  item.paymentType || "-",
                  item.attrs?.orderId || "-",
                  item.attrs?.paymentId || "-",
                  item.attrs?.refundId || "-",
                  item.attrs?.raisedBy || "-",
                  item.amount || "-",
                  lineItem?.data?.valDtls?.totInvVal || "-",
                  item.ledger?.ledgerId || "-",
                  item.ledger?.amount || "-",
                  item.ledger?.balance || "-",
                  "996311",
                  "Credit Note",
                  lineItem?.data?.docDtls?.no || "-",
                  item.invoiceConfig?.creditIrn || "-",
                  item.invoiceConfig?.creditNote || "-",
                  item?.invoiceConfig?.file || "-",
				  // "-",
                  item?.invoiceConfig?.creditNoteDate || '-',
                  item.ticket?.name || "-",
                  item.ticket?.city || "-",
				  item.invoiceConfig?.placeOfSupply || "-", /* State where the hotel is located */
                  item.ticket?.date?.in || "-",
                  item.ticket?.date?.out || "-",
                  item.ticket?.travellers[0]?.name || "-",
                  item.ticket?.travellers[0]?.email || "-",
                  item.ticket?.travellers[0]?.gender || "-",
                  item.ticket?.travellers[0]?.type || "-",
                  item.ticket?.pnr || "-",
                  (item.invoiceConfig?.crnFare?.base).toFixed(2) || "-",
                  (item.invoiceConfig?.crnFare?.slab).toFixed(2) || "-",
                  (item.invoiceConfig?.crnFare?.oth).toFixed(2) || "-",
                  (item.invoiceConfig?.crnFare?.sGst).toFixed(2) || "-",
                  (item.invoiceConfig?.crnFare?.cGst).toFixed(2) || "-",
                  (item.invoiceConfig?.crnFare?.iGst).toFixed(2) || "-",
                  // item.invoiceConfig?.crnFare?.oth || "-",
                  item.invoiceConfig?.crnFare?.total || "-",
                  item?.invoiceConfig?.invoiceResponse?.message || "-", /* Remarks Column */
                  item?.invoiceConfig?.invoiceError || "-", /* Invoice Error message */
                  item?.ticket?.criteria?.rooms?.length || "0",
                  item.ticket?.night || "-",
                  item.ticket?.travellers.length || "-",
                  item.ticket?.purchasePricePerNight || "-",
                  item.ticket?.totalPurchasePrice || "-",
                  item.ticket?.purchasePriceGstSlab || "-",
                  item.ticket?.pricePerNight || "-",
                  item.ticket?.totalSellPrice || "-",
                  item.ticket?.gstSlab || "-",
                  item?.rateType||"-",
                  ctAssured||"-",
                  PERSONS[CONFIG.email]?
          item.invoiceConfig?.platformMarkup || "-":"",
                ]);
              }
              if (
                item.invoiceConfig !== null &&
                item.invoiceConfig.cancellationCharges !== null &&
                item.invoiceConfig.cancellationCharges !== "PENDING" &&
                selectedDoc.includes('Cancellation Charges')
              ) {
                let lineItem = JSON.parse(
                  item.invoiceConfig.cancellationRequest
                );
                data.push([
                  item.id,
                  item.transId,
                  item.referenceId||"-",
                  item.provider,
                  item.providerId || item.ticket?.flowId ||  "-",
                  item.ticket?.importMerchantType || "-",
                  "Cancellation Charges",
                  item.status,
                  item.client.code,
                  item.client.attrs?.CUST_CODE || "-",
                  item.client.attrs?.gstin || "-",
                  item.client.attrs?.pan || "-",
                  item.client.attrs?.tan || "-",
                  item.client.name,
                  Moment(item.createdAt).format("DD MMM YYYY"),
                  Moment(item.createdAt).format("HH:mm a"),
                  Moment(item.updatedAt).format("DD MMM YYYY"),
                  Moment(item.updatedAt).format("HH:mm a"),
                  item.invoiceConfig?.sellerGst?.gstin||"-",
                  "Maharashtra",
                  item.invoiceConfig?.userGst?.name || "-",
                  item.invoiceConfig?.userGst?.gstin || "-",
                  placeOfSupply(item.invoiceConfig.userGst?.gstin || "-"),
                  item.paymentType,
                  item.attrs?.orderId || "-",
                  item.attrs?.paymentId || "-",
                  item.attrs?.refundId || "-",
                  item.attrs?.raisedBy || "-",
                  item.amount,
                  lineItem?.data?.valDtls?.totInvVal || "-",
                  item.refund?.ledgerId || "-",
                  item.refund?.amount || "-",
                  item.refund?.balance || "-",
                  "996311",
                  "Cancellation Charges",
                  lineItem?.data?.docDtls?.no || "-",
                  item.invoiceConfig?.cancellationIrn || "-",
                  item.invoiceConfig?.cancellationCharges || "-",
                  item?.invoiceConfig?.file || "-",
				  "-",
                  item.ticket?.name || "-",
                  item.ticket?.city || "-",
				  item.invoiceConfig?.placeOfSupply || "-", /* State where the hotel is located */
                  item.ticket?.date?.in || "-",
                  item.ticket?.date?.out || "-",
                  item.ticket?.travellers[0]?.name || "-",
                  item.ticket?.travellers[0]?.email || "-",
                  item.ticket?.travellers[0]?.gender || "-",
                  item.ticket?.travellers[0]?.type || "-",
                  item.ticket?.pnr || "-",
                  lineItem?.data?.valDtls?.assVal || "-",
                  item.invoiceConfig?.irnFare?.slab || "-",
                  `0`,
                  lineItem?.data?.valDtls?.sgstVal || "-",
                  lineItem?.data?.valDtls?.cgstVal || "-",
                  lineItem?.data?.valDtls?.igstVal || "-",
                  lineItem?.data?.valDtls?.totInvVal || "-",
                  item?.invoiceConfig?.invoiceResponse?.message || "-", /* Remarks Column */
                  item?.invoiceConfig?.invoiceError || "-", /* Invoice Error message */
                  item?.ticket?.criteria?.rooms?.length || "0",
                  item.ticket?.night || "-",
                  item.ticket?.travellers.length || "-",
                  item.ticket?.purchasePricePerNight || "-",
                  item.ticket?.totalPurchasePrice || "-",
                  item.ticket?.purchasePriceGstSlab || "-",
                  item.ticket?.pricePerNight || "-",
                  item.ticket?.totalSellPrice || "-",
                  item.ticket?.gstSlab || "-",
                  item?.rateType||"-",
                  ctAssured||"-",
                  PERSONS[CONFIG.email]?item.invoiceConfig?.platformMarkup || "-":"",
                ]);
              }
            }
          } catch (e) {}
        });
        DownloadCsv(
          `hotel_reseller_gst_bos_${Moment(STARTDATE.current.value).format(
            "DD_MM_YYYY"
          )}_${Moment(ENDDATE.current.value).format("DD_MM_YYYY")}.csv`,
          data
        );
        setLoading(false);
      },
      {
        start: Moment(STARTDATE.current.value).valueOf(),
        end: Moment(ENDDATE.current.value).add(1,'d').valueOf(),
        filterOn: DATETYPE.current.value,
      }
    );
  };


  return (
    <div style={{ width: "100%" }}>
      <Navigation
        backUrl={"back"}
        title='Hotel Reseller Bookings'
        chips={["Meta", "Hotel", "Reseller"]}
        description='Reseller bookings appears below.'
      />
      <div
        style={{
          margin: "0 -4rem",
          padding: "1rem 4rem",
          borderBottom: "1px solid #dedede",
        }}
      >
        <div className='flex horizontally center-vertically'>
          <SelectInput
            options={[
              {label: "Created At Date", value: "createdAt"},
              {label: "Document", value: "document"},
            ]}
            ref={DATETYPE}
            type='text'
            label='Date Range Filter'
            hidePlaceholder={true}
            className='mr1 flex-1'
          />
          <DateRange
            label="From Date"
            className='flex-1 ml1 mr1 mt1'
            ref={STARTDATE}
            defaultValue={dates.start}
            onChange={()=>{
              setDates({
                ...dates, start: STARTDATE.current.value
              })
            }}
          />
          <DateRange
            label="To Date"
            ref={ENDDATE}
            defaultValue={dates.end}
            className='flex-1 mr1 ml1 mt1'
            onChange={()=>{
              setDates({
                ...dates, end: ENDDATE.current.value
              })
            }}
          />
          <MultiSelectDropdown
            label="Clients"
            placeholder="Select Clients"
            options={clientList}
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
            className='ml1 mr1 mt1 flex-1'
          />
          <MultiSelectDropdown
            label="Document Type"
            placeholder="Select Document Type"
            options={docTypes}
            selectedOptions={selectedDoc}
            setSelectedOptions={setSelectedDoc}
            className='ml1 mr1 mt1 flex-1'
            selectAllOption={true}
            showSelectedOptins={false}
            enableSearch={false}
          />
        </div>
      </div>
      {
        loading
          ? ( <div className='pd6'>
            <Loader />
            </div>
          )
          : (
            <div>
            <div className='border pd6 mt4 flex vertically center'>
              <img
                alt='fetch'
                src={Graph}
                style={{ width: "5rem", height: "5rem" }}
              />
              <div className="flex mt4">
                <RBAC role={ROLES.REPORTS}>
                  <Button onClick={downloadData} className='btn btn-black '>
                    Download Data
                  </Button>
                  <Button onClick={downloadDocs} className='btn btn-black ml4'>
                    Download Documents
                  </Button>
                </RBAC>
              </div>
              <p className='mt2'>Download reseller bos data</p>
            </div>
          </div>
        )
      }
    </div>
  );
}
