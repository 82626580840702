import React, { useRef, useState } from "react";
import { Button, Input } from "retro/index";
import { get } from "App/Network/Axios";

export default function FetchDetails({ data, setData }) {
  const RID = useRef();
  const [loader, setloader] = useState(false);

  function fetchRIDetails() {
    let rid = RID.current.value;
    setloader(true);
    get(`/meta/mis/${rid}`, (e, r) => {
      if (r) {
        setData({
          RID: rid,
          details: r,
        });
        setloader(false);
      }
    });
  }

  return (
    <div>
      <div className="mt4 pd4 border rounded-md">
        <div className="flex horizontally center-vertically mt2">
          <Input
            type="text"
            ref={RID}
            label="Enter  RId"
            placeholder="Enter  RId"
            className="flex-1"
          />
        </div>

        <Button
          onClick={() => fetchRIDetails()}
          loading={loader}
          className="btn-black btn-primary mr4"
          margin="pd2 mt4"
        >
          Next
          <i className="fa-solid fa-arrow-right ml1" />
        </Button>
      </div>
    </div>
  );
}
