import React from 'react'
import {Route, Switch} from "react-router-dom";
import {NotFound} from "../../../../../retro";
import Routes from './routes'
import ImportHotel from '../All/ImportHotel';
import ImportTj from '../All/ImportTj'
import ImportCleartrip from '../All/ImportCleartrip'
import ImportVisa from '../All/ImportVisa'
import ImportBus from '../All/ImportBus';
import ImportTrain from '../All/ImportTrain';
import ImportInsurance from '../All/ImportInsurance';
import AccountMis from '../All/AccountMis';
import Tags from '../All/Tags';
import CancelAgencyController from '../All/CancelAgencyController';
import RegenerateFinancialCancellationCharges from '../All/RegenerateFinancialCancellationCharges';
import RegenerateAncillaryInvoiceController from '../All/RegenerateAncillaryInvoiceController';
import RegenerateZeroChargeInvoiceController from '../All/RegenerateZeroChargeInvoiceController';
import RegenerateCancellationChargeInvoiceController from '../All/RegenerateCancellationChargeInvoiceController';
import ChangeAgencyDateController from '../All/ChangeAgencyDateController';
import UpdateBreakupController from '../All/UpdateBreakupController';
import IRNUtilityController from "../All/IRNUtilityController"
import AddHotelGstController from '../All/AddHotelGstController';
import CancelInvoiceController from '../All/CancelInvoiceController';
import ShiftHotelEntityController from '../All/ShiftHotelEntityController';
import ShiftFlightEntityController from '../All/ShiftFlightEntityController';
import ShiftAgencyEntityController from '../All/ShiftAgencyEntityController';
import ManualInvoice from "./../All/ManualInvoice"

export default function index() {

	return (
		<Switch>
			<Route path="/app/apps/meta/import" exact>
				<Routes/>
			</Route>
			<Route path="/app/apps/meta/import/hotel" exact>
				<ImportHotel/>
			</Route>
			<Route path="/app/apps/meta/import/tj" exact>
				<ImportTj/>
			</Route>
			<Route path="/app/apps/meta/import/cleartrip" exact>
				<ImportCleartrip/>
			</Route>
			<Route path="/app/apps/meta/import/bus" exact>
				<ImportBus />
			</Route>
			<Route path="/app/apps/meta/import/train" exact>
				<ImportTrain />
			</Route>
			<Route path="/app/apps/meta/import/visa" exact>
				<ImportVisa />
			</Route>
			<Route path="/app/apps/meta/import/insurance" exact>
				<ImportInsurance />
			</Route>
			<Route path="/app/apps/meta/import/mis" exact>
				<AccountMis />
			</Route>
			<Route path="/app/apps/meta/import/tags" exact>
				<Tags/>
			</Route>
			<Route path="/app/apps/meta/import/cancelAgencySwitchToReseller" exact>
				<CancelAgencyController />
			</Route>
			<Route path="/app/apps/meta/import/regenerate/flight/cancel" exact>
				<RegenerateFinancialCancellationCharges />
			</Route>
			<Route path="/app/apps/meta/import/regenerate/flight/ancillary" exact>
				<RegenerateAncillaryInvoiceController />
			</Route>
			<Route path="/app/apps/meta/import/regenerate/flight/zeroGst" exact>
				<RegenerateZeroChargeInvoiceController />
			</Route>
			<Route path="/app/apps/meta/import/regenerate/flight/cancellationCharges" exact>
				<RegenerateCancellationChargeInvoiceController />
			</Route>
			<Route path="/app/apps/meta/import/agency/date" exact>
				<ChangeAgencyDateController />
			</Route>
			<Route path="/app/apps/meta/import/breakup" exact>
				<UpdateBreakupController />
			</Route>
			<Route path="/app/apps/meta/import/IRN" exact>
				<IRNUtilityController />
			</Route>
			<Route path="/app/apps/meta/import/addGstin" exact>
				<AddHotelGstController />
			</Route>
			<Route path="/app/apps/meta/import/cancel/invoice" exact>
				<CancelInvoiceController />
			</Route>
			<Route path="/app/apps/meta/import/hotel/invoice" exact>
				<ShiftHotelEntityController />
			</Route>
			<Route path="/app/apps/meta/import/flight/invoice" exact>
				<ShiftFlightEntityController />
			</Route>
			<Route path="/app/apps/meta/import/agency" exact>
				<ShiftAgencyEntityController />
			</Route>
			<Route path="/app/apps/meta/import/manualinvoice" exact>
				<ManualInvoice />
			</Route>
			<NotFound/>
		</Switch>
	)
}
