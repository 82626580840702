import moment from 'moment';
import React, { useState } from 'react'
import { Button, ScrollableDynamicTable } from 'retro/index';
import { DownloadCsv } from "retro/Utils";
import { get } from "App/Network/Axios";

export default function InvoiceMIS({formData,click}) {

  const [downloading, setDownloading] = useState(false);
  const transId = formData?.transId || "-"
  const downloadData = () => {
    setDownloading(true);
    get(
      `/meta/mis/${transId}`,
      (e, r) => {
        setDownloading(false);
        if (r) {
          let data = [];
          let headers = [
             'Document Number',
             "Document Type",
             "Status",
             "Merchant Name",
             "Buyer GST",
             "Buyer GST No.",
             "Seller GST",
             "Seller GST No.",
             "Services Charges",
             "cgst",
             "sgst",
             "igst",
             "Other Charges",
             "Total Amount",
             "Document Date"
          ];
          data.push(headers);
          r?.items.forEach((item) => {
            data.push([
              item.documentNumber||"-",
              item.documentType||"-",
              item.status||"-",
              item.merchantName||"-",
              item.buyerGst?.name||"-",
              item.buyerGst?.gstin||"-",
              item.sellerGst?.name||"-",
              item.sellerGst?.gstin||"-",
              item?.amount?.baseFare||"-",
              item?.amount?.cgst||"-",
              item?.amount?.sgst||"-",
              item?.amount?.igst||"-",
              item?.amount?.otherCharges||"-",
              item?.amount?.total||"-",
              item?.documentDate?moment(item.documentDate).format(
                "DD MMM YYYY HH:mm a"
              ):"-",
            ]);
          });

          DownloadCsv(
            `Invoice_Mis_${transId || ""}.csv`,
            data
          );
        } else {
          console.log("e",e)
          TOAST.handleError(e);
        }
      }
    );
  };

  return (
    <div>
      <div className='flex horizontally justify-between center'>
        <p style={{fontSize:16,fontWeight:"600"}}>Invoice MIS</p>
        <div className="download_btn">
          <Button
            className="btn btn-primary"
            loading={downloading}
            onClick={downloadData}
          >
            Download Data
          </Button>
        </div>
      </div>
        <ScrollableDynamicTable
            setPage={(page) => {}}
            width={175}
            tableStyle={{
              borderSpacing: 0,
            }}
            data={{
              items: (formData?.details?.items || []).map((item) => {
                return {
                  content: item,
                  render: [
                    {
                      children: (
                        <div>
                          <p className="text">{item.documentNumber||"-"}</p>
                        </div>
                      ),
                      weight: 1,
                    },
                    {
                      children: (
                        <div>
                          <p className="text">{item.documentType||"-"}</p>
                        </div>
                      ),
                      weight: 1,
                    },
                    {
                      weight: 1,
                      title: item.status||"-",
                    },
                    {
                      weight: 1,
                      title: item.merchantName||"-",
                    },
                    {
                      weight: 1,
                      title: item.buyerGst?.name||"-",
                    },
                    {
                      weight: 1,
                      title: item.buyerGst?.gstin||"-",
                    },
                    {
                      weight: 1,
                      title: item.sellerGst?.name||"-",
                    },
                    {
                      weight: 1,
                      title: item.sellerGst?.gstin||"-",
                    },
                    {
                      weight: 1,
                      title: item?.amount?.baseFare||"-",
                    },
                    {
                      weight: 1,
                      title: item?.amount?.cgst||"-",
                    },
                    {
                      weight: 1,
                      title: item?.amount?.sgst||"-",
                    },
                    {
                      weight: 1,
                      title: item?.amount?.igst||"-",
                    },
                    {
                      weight: 1,
                      title: item?.amount?.otherCharges||"-",
                    },
                    {
                      weight: 1,
                      title: item?.amount?.total||"-",
                    },
                    {
                      weight: 1,
                      title: moment(item.documentDate).format(
                        "DD MMM YYYY HH:mm a"
                      ),
                    },

                    {
                      weight: 0.5,
                      buttons: [
                        {
                          title: "View",
                          className: "btn-secondary btn-sm",
                          // before: <OpenIcon size="small" />,
                          onClick: () => click(item),
                        },
                      ],
                    },
                  ],
                };
              }),
            }}
            headers={[
              {
                weight: 1,
                title: "Document Number",
              },
              {
                weight: 1,
                title: "Document Type",
              },
              {
                weight: 1,
                title: "Status",
              },
              {
                weight: 1,
                title: "Merchant Name",
              },
              {
                weight: 1,
                title: "Buyer GST",
              },
              {
                weight: 1,
                title: "Buyer GST No.",
              },
              {
                weight: 1,
                title: "Seller GST",
              },
              {
                weight: 1,
                title: "Seller GST No.",
              },
              {
                weight:1,
                title:"Services Charges"

              },
              {
                weight:1,
                title:"cgst"

              },
              {
                weight:1,
                title:"sgst"

              },
              {
                weight:1,
                title:"igst"

              },
              {
                weight:1,
                title:"Other Charges"
              },
              {
                weight: 1,
                title: "Total Amount",
              },
              {
                weight: 1,
                title: "Document Date",
              },
              {
                weight: 0.5,
                title: "Action",
              },
            ]}
          />
    </div>
  )
}
