import React, { useState } from "react";
import { Button, DateRange, Navigation, TOAST } from "retro";
import Moment from "moment";
import { get } from "App/Network/Axios";
import { DownloadCsv } from "retro/Utils";
import Graph from "./graph-stats-circle.svg";
import RBAC from "App/HOCs/RBAC";
import ROLES from "App/Constants/Roles";

export default function AllUtilityLogs() {
  const [Dates, setDates] = useState();
  const [loading, setLoading] = useState(false);

  const downloadData = () => {
    setLoading(true);
    get(
      `/meta/logs`,
      (e, r) => {
        if (e) {
          setLoading(false);
          TOAST.error("Unable to download");
          console.log(e);
          return;
        }
        let data = [];
        let headers = [
          "Reference Id",
          "Trans Id",
          "Client Id",
          "Category",
          "Updated At",
          "Meta Data",
          "Owner Name",
          "Owner Email",
          "Utility Id",
          "Status",
        ];
        data.push(headers);
        r?.items?.map((row) => {
          data?.push([
            row?.referenceId || "-",
            row?.transId || "-",
            row?.clientId || "-",
            row?.category || "-",
            row?.updatedAt
              ? Moment(row?.updatedAt).format("DD-MM-YYYY HH:mm a")
              : "-",
            row?.metadata?.join("|") || "-",
            row?.owner?.ownerName || "-",
            row?.owner?.ownerEmail || "-",
            row?.utilityId || "-",
            row?.status || "-",
          ]);
        });
        DownloadCsv(
          `all_utility_logs_${Moment(Dates.startDate).format(
            "DD_MM_YYYY"
          )}_${Moment(Dates.endDate).format("DD_MM_YYYY")}.csv`,
          data
        );
        setLoading(false);
      },
      {
        start: Dates.startDate,
        end: Dates.endDate,
      }
    );
  };
  return (
    <div style={{ width: "100%" }}>
      <Navigation
        backUrl={"back"}
        title="All Utility Logs"
        description="All utility logs appears below."
      />
      <div
        style={{
          margin: "0 -4rem",
          padding: "2rem 4rem",
          borderBottom: "1px solid #dedede",
        }}
      >
        <div className="flex horizontally center-vertically">
          <div className="flex-1">
            <DateRange
              defaultValue={{
                startDate: Moment().add(-31, "d").valueOf(),
                endDate: Moment().valueOf(),
              }}
              onChange={(dates) => {
                setDates({
                  ...dates,
                  page: 0,
                });
              }}
              className=""
              label="Export from and to"
              type="range"
            />
          </div>
        </div>
      </div>
      <div>
        <RBAC role={ROLES.REPORTS}>
          <Button
            loading={loading}
            onClick={downloadData}
            className="btn btn-black"
          >
            Download Data
          </Button>
        </RBAC>

        <div className="border pd6 mt4 flex vertically center">
          <img
            alt="fetch"
            src={Graph}
            style={{ width: "5rem", height: "5rem" }}
          />
          <h3 className="fw-bold mt4">No Data Found</h3>
          <p>No data found in this configuration</p>
        </div>
      </div>
    </div>
  );
}
