import React, {useState} from 'react'
import Moment from "moment/moment";
import {Button, DateRange, Navigation, TOAST} from "retro/index";
import RBAC from "App/HOCs/RBAC";
import ROLES from "App/Constants/Roles";
import {post} from "App/Network/Axios";
import MultiSelectDropdown from 'App/Components/MultiSelectDropdown';

export default function AccountMis() {
	const [Dates, setDates] = useState({
		startDate: Moment().add(-31, 'd').valueOf(),
		endDate: Moment().valueOf(),
	});
	const [loading, setLoading] = useState(false);
	const [selectedDoc, setSelectedDoc] = useState([]);
	
	const docTypes = [
		"INVOICE",
		"SERVICE",
		"ANCILLARY",
		"PARTIAL",
		"MANAGEMENT-CREDITNOTE",
		"MANAGEMENT-CANCELLATION-INVOICE",
		"MANAGEMENT",
		"CANCELLATION",
		"CREDITNOTE",
		"ANCILLARY-CREDITNOTE",
		"INVOICE-CREDITNOTE",
		"CANCELLATION-CREDITNOTE",
		"SERVICE-CREDITNOTE",
		"MANAGEMENT-CN-REVERSAL",
		"MANAGEMENT-REVERSAL",
		"MANUAL-CREDIT-NOTE",
		"MANUAL-DEBIT-NOTE",
		"MANUAL-RESELLER-DEBIT-NOTE",
		"MANUAL-RESELLER-CREDIT-NOTE"
	];

	const downloadData = () => {
		setLoading(true);
		let postData = {
			start:`${Dates.startDate}`,
			end:`${Dates.endDate}`,
			type:'date',
		} 
		if(selectedDoc?.length){
			postData["documentType"] = selectedDoc
		}
		post(
			`/meta/report`,
			postData
			,
			(e,r) => {
				setLoading(false)
				if(e){
					TOAST.handleError(e)
					console.log("e",e)
				}else{
					var blob = new Blob([r], { type: 'text/csv;charset=utf-8;' });
					if (navigator.msSaveBlob) { // IE 10+
						navigator.msSaveBlob(blob, "reports.csv");
					} else {
						var link = document.createElement("a");
						if (link.download !== undefined) { // feature detection
							// Browsers that support HTML5 download attribute
							var url = URL.createObjectURL(blob);
							link.setAttribute("href", url);
							link.setAttribute("download", `Account_Mis_${Moment(Dates.startDate).format(
											'DD_MM_YYYY'
										  )}-${Moment(Dates.endDate).format('DD_MM_YYYY')}.csv`);
							link.style.visibility = 'hidden';
							document.body.appendChild(link);
							link.click();
							document.body.removeChild(link);
						}
					}
				}
			},
		)
	}
	return (
		<div>
			<Navigation
				backUrl={'back'}
				title="Account MIS"
				chips={['Meta', 'MIS', 'Failed', 'Invoices']}
				description="Account Invoices MIS"
			/>
			<div>
				<div className="border pd4 flex center">
					<DateRange
						defaultValue={{
							startDate: Moment().add(-31, 'd').valueOf(),
							endDate: Moment().valueOf(),
						}}
						onChange={(dates) => {
							setDates({
								...dates,
								page: 0,
							});
						}}
						className="flex-1 mt1"
						label="Select Date Range"
						type="range"
					/>
					<MultiSelectDropdown
						label="Document Type"
						placeholder="Select Document Type"
						options={docTypes}
						selectedOptions={selectedDoc}
						setSelectedOptions={setSelectedDoc}
						className='ml1 mr1 mt1 flex-1'
						selectAllOption={true}
						showSelectedOptins={false}
						enableSearch={false}
					/>
					<RBAC role={ROLES.REPORTS}>
						<Button
							onClick={downloadData}
							className="btn btn-black mt4"
							loading={loading}
						>
							Download Data
						</Button>
					</RBAC>
				</div>
			</div>
		</div>
	)
}
