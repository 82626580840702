import { post } from "App/Network/Axios";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Button, Input, SelectInput, SidePane, TOAST } from "retro/index";

export default function Credit({
  creditModal,
  setcreditModal,
  formData,
  fetchRIDetails,
  fetchDADetails,
}) {
  const { transId, details } = formData;
  const REMARKS = useRef();
  const SELLERGST = useRef();
  const HSN = useRef();
  const [loader, setloader] = useState(false);
  const SLAB = useRef();
  const [breakup, setbreakup] = useState([{ key: "", value: "" }]);
  const [baseFare, setbaseFare] = useState(undefined);
  const [slab, setslab] = useState(0.18);
  const [igst, setigst] = useState(undefined);
  const [sgst, setsgst] = useState(undefined);
  const [cgst, setcgst] = useState(undefined);
  const [otherCharges, setotherCharges] = useState(undefined);
  const [amount, setAmount] = useState(undefined);
  const [sellerGstin, setsellerGstin] = useState(undefined);
  const [buyerGstin, setbuyerGstin] = useState(undefined);
  let isHotel = details?.category?.toLowerCase()?.includes("hotel");
  let isReseller = details?.reseller;

  let GSTOption = {
    "Andhra Pradesh": {
      GSTIN: "37AAHCC1775A1ZW",
    },
    "Arunachal Pradesh": {
      GSTIN: "12AAHCC1775A1Z8",
    },
    Assam: {
      GSTIN: "18AAHCC1775A1ZW",
    },
    Bihar: {
      GSTIN: "10AAHCC1775A1ZC",
    },
    Chandigarh: {
      GSTIN: "04AAHCC1775A1Z5",
    },
    Chattisgarh: {
      GSTIN: "22AAHCC1775A1Z7",
    },
    "Dadra Nagar Haveli": {
      GSTIN: "26AAHCC1775A1ZZ",
    },
    Delhi: {
      GSTIN: "07AAHCC1775A1ZZ",
    },
    Goa: {
      GSTIN: "30AAHCC1775A1ZA",
    },
    Gujarat: {
      GSTIN: "24AAHCC1775A1Z3",
    },
    Haryana: {
      GSTIN: "06AAHCC1775A1Z1",
    },
    "Himachal Pradesh": {
      GSTIN: "02AAHCC1775A1Z9",
    },
    "Jammu And Kashmir": {
      GSTIN: "01AAHCC1775A1ZB",
    },
    Jharkhand: {
      GSTIN: "20AAHCC1775A1ZB",
    },
    Karnataka: {
      GSTIN: "29AAHCC1775A1ZT",
    },
    Kerala: {
      GSTIN: "32AAHCC1775A1Z6",
    },
    "Madhya Pradesh": {
      GSTIN: "23AAHCC1775A1Z5",
    },
    MAHARASHTRA: {
      GSTIN: "27AAHCC1775A1ZX",
    },
    Meghalaya: {
      GSTIN: "17AAHCC1775A1ZY",
    },
    Nagaland: {
      GSTIN: "13AAHCC1775A1Z6",
    },
    Orissa: {
      GSTIN: "21AAHCC1775A1Z9",
    },
    Punjab: {
      GSTIN: "03AAHCC1775A1Z7",
    },
    Rajasthan: {
      GSTIN: "08AAHCC1775A1ZX",
    },
    "Tamil Nadu": {
      GSTIN: "33AAHCC1775A1Z4",
    },
    Telangana: {
      GSTIN: "36AAHCC1775A1ZY",
    },
    Tripura: {
      GSTIN: "16AAHCC1775A1Z0",
    },
    "Uttar Pradesh": {
      GSTIN: "09AAHCC1775A1ZV",
    },
    UTTARAKHAND: {
      GSTIN: "05AAHCC1775A1Z3",
    },
    "West Bengal": {
      GSTIN: "19AAHCC1775A1ZU",
    },
  };

  useEffect(() => {
    let sortedItem = _.sortBy(details?.items, ["createdAt"])?.reverse();
    if (!details?.category?.toLowerCase()?.includes("hotel")) {
      setsellerGstin("27AAHCC1775A1ZX");
    } else {
      setsellerGstin(sortedItem?.[0]?.sellerGst?.gstin);
    }
    setbuyerGstin(sortedItem?.[0]?.buyerGst?.gstin);
  }, []);

  useEffect(() => {
    if (baseFare && slab && buyerGstin) {

      

        if (sellerGstin?.substring(0, 2) === buyerGstin?.substring(0, 2)) {
          setsgst((Number(baseFare) * Number(slab)) / 2);
          setcgst((Number(baseFare) * Number(slab)) / 2);
          setigst(0);
        } else {
          setigst(Number(baseFare) * Number(slab));
          setsgst(0);
          setcgst(0);
        }
      

    } else {
      setsgst(0);
      setcgst(0);
      setigst(0);
    }
  }, [baseFare, slab, sellerGstin, buyerGstin]);

  function onClose() {
    setcreditModal(false);
  }
  const [gstslabOption, setgstslabOption] = useState(() => {
    if (isHotel) {
      return [
        {
          value: 0.12,
          label: "12%",
        },
        {
          value: 0.18,
          label: "18%",
        },
      ];
    }

    return [{ value: 0.18, label: "18%" }];
  });

  const [HSNOption, setHSNOption] = useState(() => {
    if (isHotel) {
      return [
        {
          value: "998559",
          label: "New Reseller(998559)",
        },
        {
          value: "996311",
          label: "Old Reseller(996311)",
        },
        {
          value: "998551",
          label: "Agency(998551)",
        },
      ];
    }

    return [
      {
        value: "998551",
        label: "Agency(998551)",
      },
    ];
  });

  useEffect(() => {
    setAmount(
      Number(igst ? igst : 0) +
        Number(sgst ? sgst : 0) +
        Number(cgst ? cgst : 0) +
        Number(baseFare ? baseFare : 0) +
        Number(otherCharges ? otherCharges : 0)
    );
  }, [sgst, igst, cgst, baseFare, otherCharges]);

  function creditFun() {
    const remarks = REMARKS.current.value;
    const hsn = HSN?.current?.value;
    const slab = SLAB.current.value;

    if (!slab) {
      TOAST.error("Select GST slab");
      return;
    }
    if (!baseFare) {
      TOAST.error("Enter Base Fare");
      return;
    }

    if (!buyerGstin) {
      TOAST.error("Enter Buyer Gst");
      return;
    }

    if (!remarks) {
      TOAST.error("Please Enter Remarks");
      return;
    }
    let LocalBreakup = {};

    breakup
      ?.filter((i) => i.key)
      .map((item) => {
        LocalBreakup[item.key] = item.value;
      });

    let payload = {
      sellerGstin: isHotel ? sellerGstin : undefined,
      buyerGstin,
      amount: {
        total: amount,
        igst: Number(igst),
        sgst: Number(sgst),
        cgst: Number(cgst),
        baseFare: Number(baseFare),
        otherCharges: (!isHotel || !isReseller) ?   Number(otherCharges):undefined,
        slab: Number(slab),
      },
      remarks,
      hsn: isHotel ? hsn : undefined,
      breakup: (!isHotel || !isReseller)
        ? breakup?.length
          ? LocalBreakup
          : undefined
        : undefined,
    };

    setloader(true);

    post(`/meta/mis/${transId}/credit`, payload, (e, r) => {
      if (r) {
        setloader(false);
        TOAST.success("Credit invoice create successfully!");
        fetchRIDetails();
        fetchDADetails();
        onClose();
      } else {
        TOAST.handleError(e);
        setloader(false);
      }
    });
  }
  return (
    <>
      {creditModal && (
        <SidePane
          title="Credit Note Details"
          description=""
          actions={
            <Button
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
              }}
              className="btn btn-primary"
              onClick={creditFun}
              loading={loader}
            >
              <p>Credit {amount ? `( INR ${amount} )` : ""}</p>
            </Button>
          }
          onClose={onClose}
        >
          <div className="text-small mt4">
            <h3 className="mb2">Credit Note Details</h3>
            {/* seller */}
            {details?.category?.toLowerCase()?.includes("hotel") ? (
              <SelectInput
                onChange={(val) => {
                  setsellerGstin(val);
                }}
                options={Object.keys(GSTOption)?.map((i) => {
                  return {
                    label: `${i}(${GSTOption?.[i]?.GSTIN})`,
                    value: GSTOption?.[i]?.GSTIN,
                  };
                })}
                ref={SELLERGST}
                type="text"
                label="Seller GST"
                placeholder="Seller GST"
                defaultValue={sellerGstin}
              />
            ) : (
              <></>
            )}

            {/* buyer gst */}

            {details?.category?.toLowerCase()?.includes("hotel") ? (
              <Input
                type="text"
                label="Buyer Gstin"
                placeholder="Buyer Gstin"
                defaultValue={buyerGstin}
                onChange={(val) => {
                  setbuyerGstin(val);
                }}
              />
            ) : (
              <Input
                type="text"
                label="Buyer Gstin"
                placeholder="Buyer Gstin"
                onChange={(val) => {
                  setbuyerGstin(val);
                }}
                defaultValue={
                  details?.items[details?.items?.length - 1]?.buyerGst?.gstin
                }
              />
            )}

            <Input
              ref={REMARKS}
              type="text"
              label="Remarks*"
              placeholder="Remarks"
            />
            {details?.category?.toLowerCase()?.includes("hotel") && (
              <SelectInput
                ref={HSN}
                options={HSNOption}
                type="text"
                label="HSN"
                placeholder="HSN"
              />
            )}

            <Input
              onChange={(val) => {
                setbaseFare(val);
              }}
              type="text"
              label="Services charges/Hotel Tariff"
              placeholder="Services Charges/Hotel Tariff"
            />
            <SelectInput
              ref={SLAB}
              onChange={(val) => {
                setslab(val);
              }}
              options={gstslabOption}
              type="text"
              label="GST Slab"
              placeholder="GST Slab"
              defaultValue={slab}
            />

            <div
              style={{
                flexDirection: "row",
                display: "flex",
                flex: 1,
                width: "100%",
              }}
            >
              <Input
                onChange={(val) => {
                  setcgst(val);
                }}
                style={{ flex: 1 }}
                type="text"
                defaultValue={cgst?.toString()}
                label="CGST"
                placeholder="CGST"
                disabled
              />
              <Input
                onChange={(val) => {
                  setsgst(val);
                }}
                type="text"
                style={{ marginLeft: 20, flex: 1 }}
                defaultValue={sgst?.toString()}
                label="SGST"
                placeholder="SGST"
                disabled
              />
            </div>
            <Input
              onChange={(val) => {
                setigst(val);
              }}
              type="text"
              defaultValue={igst?.toString()}
              label="IGST"
              placeholder="IGST"
              disabled
            />

            {(!isHotel || !isReseller) && (
              <Input
                type="text"
                onChange={(val) => {
                  setotherCharges(val);
                }}
                label="Others Charges/Total Ticket Fare"
                placeholder="Total Ticket Fare"
              />
            )}

            {(!isHotel || !isReseller) && (
              <>
                <p style={{ marginTop: 10, fontSize: 16, fontWeight: "600" }}>
                  BreakUP
                </p>
                {breakup.map((i, index) => {
                  return (
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <Input
                        type="text"
                        label=""
                        placeholder="Key"
                        defaultValue={i.key}
                        onChange={(val) => {
                          setbreakup((p) => {
                            p[index].key = val;
                            return [...p];
                          });
                        }}
                      />
                      <div
                        style={{ paddingTop: 4, marginTop: 10, marginLeft: 8 }}
                      >
                        <input
                          type="text"
                          value={i.value}
                          placeholder="Value"
                          min={0}
                          onChange={(event) => {
                            if (!/^[0-9]*$/.test(event.target.value)) {
                              return;
                            }
                            setbreakup((p) => {
                              p[index].value = event.target.value;
                              return [...p];
                            });
                          }}
                        />
                      </div>
                      <i
                        className="fa-solid fa-plus ml4 mt5"
                        onClick={() => {
                          setbreakup((p) => {
                            return [...p, { key: "", value: "" }];
                          });
                        }}
                      />
                      {index !== 0 && (
                        <i
                          className="fa-solid fa-trash ml2 mt5"
                          onClick={() => {
                            setbreakup((p) => {
                              return p?.filter((i, idx) => index !== idx);
                            });
                          }}
                        />
                      )}
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </SidePane>
      )}
    </>
  );
}
